import React, { useContext } from 'react';

import {
  IApplicationInfoDetailsViewProperties,
} from '../../../view/user/page/application/info/parts/application/ApplicationInfoDetailsView';
import { MenuItem, Theme, Typography } from '@mui/material';
import { Version } from '../../../view/user/page/application/info/store/ApplicationDetailUI';
import {
  IApplicationRequirementContainerProperties,
} from '../../../view/user/page/application/info/parts/requirements/ApplicationRequirementContainer';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ICoreSearchAudit } from '../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { IApplicationModel } from '../../../service/application/entity/IApplicationModel';
import { ContainerContext } from '../../../common/container/ContainerContext';
import { generateName } from '../../../view/user/page/application/info/parts/report/ApplicationInfoReport';

export const QuestionnaireVersionExtended = ({ domain }: IApplicationInfoDetailsViewProperties) => {
  return (
    <Typography variant='h6'>
      Анкета АС версии {domain.ui.getVersionWithStatus({ versionType: Version.major })}
    </Typography>
  );
}

export const RequirementVersionExtended = observer(({ domain }: IApplicationRequirementContainerProperties) => {
  const classes = useStyles();
  const status = domain.ui.getVersionWithStatus({ versionType: Version.minor });

  return (
    <div className={classes.table}>
      <Typography variant={'h6'}>
        Требования АС версии {status}
      </Typography>
    </div>
  );
});

export const ExtendedQuestionnaireDownload =
  observer(({
              domain,
              format,
              audit,
              isNeedColor,
              popupState,
              application
            }: {
    domain: ApplicationDetailDomain,
    format: string,
    audit?: ICoreSearchAudit,
    isNeedColor: boolean,
    popupState: any,
    application?: IApplicationModel,
  }) => {
    const container = useContext(ContainerContext);
    const extendedGenerateName = container.get(generateName);
    const reportBtnMajor = ' версии ' + domain.ui.getVersionWithStatus({versionType: Version.major, application});
    const reportNameMajor = '_версия ' + domain.ui.getVersionWithStatus({versionType: Version.major, application});

    return (<MenuItem
      onClick={() => {
        domain.downloadApplicationQuestionnaire({
          format,
          audit,
          isNeedColor: true,
          reportName:
            `${extendedGenerateName({ domain, reportName: 'Анкета ИБ' })}${reportNameMajor}`,
        });
        popupState.close();
      }}
    >
      Анкета ({format}){reportBtnMajor}
    </MenuItem>);
  })

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    paddingLeft: '20px',
  },
}));
