import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FormActions } from '../../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../../design/form/FormAutocomplete';
import { FormBox } from '../../../../../../../design/form/FormBox';
import { FormCheckboxField } from '../../../../../../../design/form/FormCheckboxField';
import { FormTextField } from '../../../../../../../design/form/FormTextField';
import { ProjectButton } from '../../../../../../../design/link/ProjectButton';
import { IAdminPageProperties } from '../../../../../IAdminPageProperties';
import { FormModalContainer } from '../../../../../../../design/dataTable/form/view/FormModalContainer';
import { ActiveDirectoryModalDomain } from './ActiveDirectoryModalDomain';

export interface IActiveDirectoryServerAdminFormProperties extends IAdminPageProperties { }

export const ActiveDirectoryServerAdminForm = observer(
  ({ domain }: { domain: ActiveDirectoryModalDomain }) => {

    const { serverId = null } = useParams<any>();

    useEffect(() => {
      domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
    }, [domain, serverId]);

    return (
      <FormModalContainer onClose={domain.onCancelUpdates}>
        <Grid sx={{ overflowY: 'scroll', maxHeight: '703px' }}>
          <ActiveDirectoryNameCustomFieldText domain={domain} />
          <ActiveDirectoryLdapCustomFieldText domain={domain} />
          <ActiveDirectoryLoginCustomFieldText domain={domain} />
          <ActiveDirectoryPasswordCustomFieldText domain={domain} />
          <ActiveDirectoryDNCustomFieldText domain={domain} />
          <ActiveDirectoryLoginMethodCustomCheckBox domain={domain} />
          <ActiveDirectoryActivateConnectionCustomCheckBox domain={domain} />
          <ActiveDirectoryUsersConnectionsBlock domain={domain} />
          <FormActions
            onSave={() => { domain.save() }}
            onCancel={() => domain.onCancelUpdates()}
            isLoading={domain.ui.isLoading.value} />
        </Grid>
      </FormModalContainer>

    );
  },
);

const ActiveDirectoryNameCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="Название"
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      required={true}
      errorMessage={domain.getValidationErrorFor('name')?.message}
    />
  );
});

const ActiveDirectoryLdapCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="URL"
      value={domain.ui.model.entity.url || 'ldap://'}
      onChange={domain.getUpdateFieldHandler('url')}
      required={true}
      errorMessage={
        domain.getValidationErrorFor('url')?.message || domain.getValidationErrorFor('isConnected')?.message
      }
    />
  );
});

const ActiveDirectoryLoginCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="Логин"
      value={domain.ui.model.entity.username}
      onChange={domain.getUpdateFieldHandler('username')}
      required={true}
      errorMessage={domain.getValidationErrorFor('username')?.message}
    />
  );
});

const ActiveDirectoryPasswordCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="Пароль"
      value={domain.ui.model.entity.password}
      onChange={domain.getUpdateFieldHandler('password')}
      required={true}
      type={'password'}
      errorMessage={domain.getValidationErrorFor('password')?.message}
    />
  );
});

const ActiveDirectoryDNCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="DN"
      value={domain.ui.model.entity.baseDN}
      onChange={domain.getUpdateFieldHandler('baseDN')}
      required={true}
      errorMessage={domain.getValidationErrorFor('baseDN')?.message}
    />
  );
});

const ActiveDirectoryLoginMethodCustomCheckBox = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormCheckboxField
      label="Использовать аутентификацию с использованием данного AD по умолчанию"
      value={domain.ui.model.entity.isMainLoginMethod}
      defaultValue={false}
      onChange={domain.getUpdateFieldHandler('isMainLoginMethod')}
      errorMessage={domain.getValidationErrorFor('isMainLoginMethod')?.message}
      dataCy='choose-ad-auth-by-default'
    />
  );
});

const ActiveDirectoryActivateConnectionCustomCheckBox = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormCheckboxField
      onChange={domain.getUpdateFieldHandler('isActive')}
      label="Активировать данное подключение"
      value={domain.ui.model.entity.isActive}
      defaultValue={false}
      errorMessage={domain.getValidationErrorFor('isMainLoginMethod')?.message}
      dataCy='activate-connection'
    />
  );
});

const ActiveDirectoryUsersConnectionsBlock = observer((
  { domain }: { domain: ActiveDirectoryModalDomain }
) => {
  return (
    <FormBox>
      <Grid flexDirection={'column'} spacing={3} container>
        <Grid item>
          <ProjectButton
            icon={<AddIcon />}
            action={() => {
              if (!domain.ui.model.entity.systemRoleToAdGroup?.mapping) {
                domain.ui.model.entity.systemRoleToAdGroup = { mapping: [] };
              }
              domain.ui.model.entity.systemRoleToAdGroup.mapping.push({ systemRoleId: null, groupName: '' });
            }}
          >
            Добавить связь
          </ProjectButton>
        </Grid>
        {domain.ui.model.entity?.systemRoleToAdGroup?.mapping?.map((item, index) => {
          return (
            <Grid item key={`ADform ${index}`}>
              <Grid container spacing={1} flexDirection={'row'}>
                <Grid style={{ width: 300 }} item>
                  <FormAutocomplete
                    values={domain.systemRoles.list.map((item) => ({ value: item.id, name: item.name }))}
                    onChangeSelect={(newValue: any) => {
                      item.systemRoleId = newValue.value || null;
                    }}
                    selected={item.systemRoleId}
                    label="Системная роль"
                  />
                </Grid>
                <Grid style={{ width: 300 }} item>
                  <FormTextField
                    value={item.groupName}
                    onChange={(newValue: any) => {
                      item.groupName = newValue || '';
                    }}
                    label="Группа пользователей"
                    required={false}
                  />
                </Grid>
                <Grid style={{ paddingTop: 25 }} item>
                  <ProjectButton
                    action={() => {
                      domain.ui.model.entity.systemRoleToAdGroup.mapping = domain.ui.model.entity.systemRoleToAdGroup.mapping.filter(
                        (link, itemIndex) => {
                          return itemIndex !== index;
                        },
                      );
                    }}
                  >
                    Удалить связь
                  </ProjectButton>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </FormBox>
  )
})
